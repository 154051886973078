<template>
  <div>
    <!-- 添加新成员 -->
    <el-dialog :title="editUserInfo.boxtitile" class="adduserdia" center :visible.sync="adduserVisible" width="30%" :close-on-click-modal="false">
      <el-form ref="userruleForm" label-width="80px" size="mini" :model="userInfo" :inline="false" :rules="add_rules">
        <el-form-item label="账号">
          <el-input v-if="editUserInfo.IsEdit" v-model="userInfo.UserName" autocomplete="off" />
          <span v-if="!editUserInfo.IsEdit" class="m-left-30">{{ userInfo.UserName }}</span>
        </el-form-item>
        <el-form-item v-if="editUserInfo.IsEdit && isshowpassword" label="密码">
          <el-input v-model="userInfo.Pwd" autocomplete="off" />
        </el-form-item>
        <el-form-item v-if="!editUserInfo.IsEdit" label="密码">
          <el-input v-model="userInfo.Pwd" class="m-left-30" placeholder="********">
            <template slot="append">
              <span style="cursor: pointer" @click="()=> { editUserInfo.IsEdit = true; isshowpassword = true}">重置密码</span>
            </template>
          </el-input>
        </el-form-item>
        <el-form-item v-if="editUserInfo.IsEdit && isshowpassword" label="确认密码" prop="repwd">
          <el-input v-model="userInfo.repwd" />
        </el-form-item>
        <!-- <div class="adduserinfoTips">
          员工创建成功后账号密码以邮件方式发送到该员工联系邮箱<br>请核对员工账号联系邮箱</div> -->
        <el-divider />
        <el-form-item label="姓名" prop="NickName">
          <el-input v-if="editUserInfo.IsEdit" v-model="userInfo.NickName" />
          <span v-if="!editUserInfo.IsEdit" class="m-left-30">{{ userInfo.NickName }}</span>
        </el-form-item>
        <el-form-item label="Email" prop="EMail">
          <el-input v-if="editUserInfo.IsEdit" v-model="userInfo.EMail" />
          <span v-if="!editUserInfo.IsEdit" class="m-left-30">{{ userInfo.EMail }}</span>
        </el-form-item>
        <el-form-item label="手机" prop="Mobile">
          <el-input v-if="editUserInfo.IsEdit" v-model="userInfo.Mobile" />
          <span v-if="!editUserInfo.IsEdit" class="m-left-30">{{ userInfo.Mobile }}</span>
        </el-form-item>
        <el-form-item label="性别" prop="Sex">
          <el-select v-if="editUserInfo.IsEdit" v-model="userInfo.Sex" placeholder="请选择性别" style="width:100%">
            <el-option label="男" :value="0" />
            <el-option label="女" :value="1" />
          </el-select>
          <span v-if="!editUserInfo.IsEdit" class="m-left-30">{{ userInfo.Sex | userGenderName }}</span>
        </el-form-item>
        <el-form-item label="职务描述" prop="JobName">
          <el-input v-if="editUserInfo.IsEdit" v-model="userInfo.JobName" autocomplete="off" />
          <span v-if="!editUserInfo.IsEdit" class="m-left-30">{{ userInfo.JobName }}</span>
        </el-form-item>
        <el-form-item label="用户编号">
          <el-input v-if="editUserInfo.IsEdit" v-model="userInfo.ExeID" autocomplete="off" />
          <span v-if="!editUserInfo.IsEdit" class="m-left-30">{{ userInfo.ExeID }}</span>
        </el-form-item>
        <el-form-item label="办公电话">
          <el-input v-if="editUserInfo.IsEdit" v-model="userInfo.OfficeTel" autocomplete="off" />
          <span v-if="!editUserInfo.IsEdit" class="m-left-30">{{ userInfo.OfficeTel }}</span>
        </el-form-item>
        <el-form-item label="qq号码">
          <el-input v-if="editUserInfo.IsEdit" v-model="userInfo.QQ" autocomplete="off" />
          <span v-if="!editUserInfo.IsEdit" class="m-left-30">{{ userInfo.QQ }}</span>
        </el-form-item>
        <el-form-item label="工号">
          <el-input v-if="editUserInfo.IsEdit" v-model="userInfo.WorkNum" autocomplete="off" />
          <span v-if="!editUserInfo.IsEdit" class="m-left-30">{{ userInfo.WorkNum }}</span>
        </el-form-item>
        <el-divider />
        <el-form-item v-if="editUserInfo.IsEdit" label="部门">
          <div v-for="(item, index) in usercheckeddeptarr" :key="index" class="deptarr flex">
            <el-cascader v-model="item.deptvalue" :options="item.sourceData" style="width:40%; padding-right:10px" @change="userdepthandleChange(item.deptvalue,index)">
              <template slot-scope="{ data }">
                <span>{{ data.label }}</span>
              </template>
            </el-cascader>
            <div style="width:60%">
              <el-select v-model="item.userdepttype" placeholder="请选择职务" style="width:calc(100% - 30px); padding-right:10px">
                <el-option label="成员" :value="0" />
                <el-option label="部门主管" :value="1" />
                <el-option label="分管领导" :value="2" />
              </el-select>
              <span class="deptremove" @click="removesuerdeptarr(index)"><i class="el-icon-delete" /></span>
            </div>
          </div>
          <div class="adduseradddept" @click="userdeptpush()"><i class="el-icon-circle-plus" /> 添加部门/职位</div>
        </el-form-item>
        <el-form-item v-if="!editUserInfo.IsEdit" label="部门">
          <span v-for="(item, index) in userInfo.PermissionGroupInfoList" :key="index" class="deptInfoshow">
            <span v-if="!editUserInfo.IsEdit" class="deptInfoshowleft">{{ item.GroupName }}</span>
            <span v-if="!editUserInfo.IsEdit" class="deptInfoshowright">{{ item.UserIdentity | userdeptTypeName }}</span>
            <br>
          </span>
        </el-form-item>
        <el-form-item label="直属领导">
          <div class="roleuser">
            <el-button v-if="editUserInfo.IsEdit" class="adduserbuton" size="small" icon="el-icon-plus" circle @click="openconnUser(6)" />
            <el-tooltip v-if="userInfo.LeaderUserID > 0" class="item" effect="dark" :content="userInfo.LeaderUserName" placement="bottom">
              <div class="addedUser">
                <i class="el-icon-user" />
                <span v-if="editUserInfo.IsEdit" class="close" @click="userInfo.LeaderUserID = 0">x</span>
              </div>
            </el-tooltip>
          </div>
        </el-form-item>
        <el-form-item label="角色">
          <el-select v-if="editUserInfo.IsEdit" v-model="userInfo.userroles" style="width:100%" multiple placeholder="请选择" @change="userroleschange">
            <el-option
              v-for="(item, index) in userrolesDataList"
              :key="index"
              :label="item.RoleName"
              :value="item.IDX"
            />
          </el-select>
          <div v-if="!editUserInfo.IsEdit">
            <span
              v-for="(item, index) in userrolesDataList"
              :key="index"
            >
              <span class="deptInfoshowleft">
                {{ item.RoleName }}
              </span>
            </span>
          </div>
        </el-form-item>
        <el-form-item label="账号类型" label-width="80px">
          <el-radio v-model="userInfo.UserClass" :disabled="!editUserInfo.IsEdit" :label="1">管理员</el-radio>
          <el-radio v-model="userInfo.UserClass" :disabled="!editUserInfo.IsEdit" :label="2">普通</el-radio>
        </el-form-item>
        <el-form-item label="是否启用">
          <el-radio v-model="userInfo.IsValid" :disabled="!editUserInfo.IsEdit" :label="0">不启用</el-radio>
          <el-radio v-model="userInfo.IsValid" :disabled="!editUserInfo.IsEdit" :label="1">启用</el-radio>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer t-right t_line_s p-top-20">
        <el-button v-if="editUserInfo.IsEdit" @click="adduserVisible = false">取 消</el-button>
        <el-button v-if="editUserInfo.IsEdit" type="primary" @click="edituserInfo_save">确 定</el-button>
        <el-button v-if="!editUserInfo.IsEdit" type="primary" @click="() => { editUserInfo.IsEdit= !editUserInfo.IsEdit; isshowpassword = false}">编 辑</el-button>
        <el-button v-if="!editUserInfo.IsEdit" type="primary" @click="dealUserPower(userInfo)">权限设置</el-button>
      </div>
    </el-dialog>
    <!-- 选择成员 -->
    <el-dialog class="DepartmentBox" title="添加新成员" center :visible.sync="seletUserVisible" width="25%" :close-on-click-modal="false">
      <div class="rolebody">
        <el-tabs v-model="selectUseractiveName" @tab-click="selectUseractiveNamehandleClick">
          <el-tab-pane label="成员" name="user" />
        </el-tabs>
        <div v-if="selectUseractiveName === 'user'" class="selectuser">
          <el-input
            v-model="selectUserkeywords"
            size="small"
            placeholder="搜索成员"
            prefix-icon="el-icon-search"
          />
          <div class="selectUserTree">
            <el-tree
              ref="allusertree"
              :data="connuserlist"
              node-key="value"
              highlight-current
              :filter-node-method="alluserfilterNode"
            >
              <span slot-scope="{ node, data }" style="width:100%">
                <span class="roleSpan"><div class="deptSpandiv"><i class="el-icon-user" /></div></span>
                <span class="deptnamespan">
                  {{ node.label }}</span>
                <!-- nodeType- 0：部门 1：员工 -->
                <span v-if="data.nodeType === 1" class="deptcountspan"><el-checkbox v-model="data.ischecked" @change="checkeduser(data.ischecked,data.value,data)" /></span>
              </span>
            </el-tree>
          </div>
        </div>
      </div>
      <div slot="footer" class="dialog-footer t-right">
        <el-button @click="seletUserVisible = false">取 消</el-button>
        <el-button type="primary" @click="doconnuser">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
// import PageHeader from '@/components/PageHeader.vue'
import $request from '@/request/contactsapi.js'
export default {
  name: 'AddUser',
  // components: {
  // PageHeader
  // },
  filters: {
    userdeptTypeName: function(value) {
      switch (value) {
        case 0:
          return '成员'
        case 1:
          return '部门主管'
        case 2:
          return '分管领导'
        default: return ''
      }
    },
    userGenderName: function(value) {
      switch (value) {
        case 0:
          return '男'
        case 1:
          return '女'
        default: return ''
      }
    }
  },
  data() {
    var repwd = (rule, value, callback) => {
      if (!value) {
        callback(new Error('请设置密码'))
      }
      if (value !== this.userInfo.Pwd) {
        callback(new Error('两次密码不一致'))
      } else {
        callback()
      }
    }
    return {
      add_fullscreenLoading: false, // 全局loading
      add_rules: {
        NickName: [{ required: true, message: '请填写人员名称', trigger: 'blur' }],
        EMail: [{ required: true, message: '请填写人员邮箱', trigger: 'blur' }],
        Mobile: [{ required: true, message: '请填写人员手机', trigger: 'blur' }],
        Sex: [{ required: true, message: '请选择性别', trigger: 'change' }],
        JobName: [{ required: true, message: '请填写人员职务', trigger: 'change' }],
        // 确认密码
        repwd: [
          { validator: repwd, trigger: 'change' }
        ]
      },
      // 新增人员
      editUserInfo: {
        boxtitile: '新增人员',
        IsEdit: false
      }, // 编辑人员框 状态
      adduserVisible: false,
      userInfo: {
        UserID: 0,
        NickName: '', // 人员名称
        UserName: '', // 账号
        ExeID: '', // 用户编号
        Pwd: 'fa',
        repwd: 'fdfdsasa ',
        EMail: '',
        Mobile: '',
        Sex: null,
        JobName: '',
        usernum: '',
        OfficeTel: '', // 办公电话
        QQ: '', // qq号
        WorkNum: '', // 工号
        PermissionGroupInfoList: [], // 部门
        IsValid: '1', // 是否启用(1：启用 0：不启用)
        RoleList: [],
        LeaderUserID: 119580, // 直属领导,
        LeaderUserName: 'fsda', // 直属领导
        UserClass: 2
      },
      userdeptsdata: [
        {
          value: 2219,
          label: 'Saas部门fdsfdsa  ',
          children: [{ value: 22112,
            label: 'fdsaffdfa'
          }]
        }
      ], // 新增人员 部门数据源
      usercheckeddeptitem: {
        deptvalue: null,
        sourceData: [
          {
            id: 2,
            value: 2219,
            label: 'fdsafsfsdafddfasa ',
            children: [{ value: 22112,
              label: 'fdsaffdfa'
            }]
          }
        ],
        userdepttype: '0',
        userdepthandleChange: 'userdepthandleChange'
      }, // 每一个选择部门数据源
      usercheckeddeptarr: [{
        deptvalue: null,
        sourceData: [
          {
            id: 2,
            value: 2219,
            label: 'Saas部门fds ',
            children: [{ value: 22112,
              label: 'fdsaffdfa'
            }]
          }
        ],
        userdepttype: '0',
        userdepthandleChange: 'userdepthandleChange'
      },
      {
        deptvalue: null,
        sourceData: [
          {
            id: 1,
            value: 2219,
            label: 'test12313',
            children: [{ value: 22112,
              label: 'fdsaffdfa'
            }]
          }
        ],
        userdepttype: '0',
        userdepthandleChange: 'userdepthandleChange'
      }],
      // 新增人员直属领导
      addedUserLeaderData: [{
        userId: 1,
        username: 'monica'
      }],
      // 新增人员 角色datalist
      userrolesDataList: [
        {
          idx: 1,
          rolename: '1法定分散fsdaf 2321'
        },
        {
          idx: 2,
          rolename: 'fdsa'
        }
      ],
      // 选择人员
      seletUserVisible: false,
      selectUseractiveName: 'user', // 选择人员
      selectUserkeywords: '',
      selectUserDeptData: [{
        value: 1,
        label: '销售部fsa11ds',
        count: 111,
        ischecked: false,
        children: [{
          value: 4,
          label: '销售部1',
          count: 2,
          ischecked: false,
          children: [{
            value: 9,
            count: 3,
            label: '销发送售部2',
            ischecked: false
          }, {
            value: 10,
            count: 5,
            label: '销售部3',
            ischecked: false
          }]
        }]
      }],
      // 选择人员 部门数据源
      selectUserroleData: [
        {
          idx: 1,
          name: '销售员',
          count: 2,
          ischecked: false
        },
        {
          idx: 2,
          name: '订单员',
          count: 2,
          ischecked: false
        },
        {
          idx: 3,
          name: '采购员',
          count: 2,
          ischecked: false
        }
      ], // 选择人员 角色数据源
      selectUserallData: [], // 选择人员 部门以及人员数据源
      // 选择成员
      connuserlist: [], // 成员
      connuserdeptlist: [], // 部门
      connuserrolelist: [], // 角色
      selectuserType: 0, // 选择人员类型 1： 角色添加人员 2 部门添加人员 3：新增角色时 4：新增部门时
      checkeduserIdList: [], // 选中的人员
      checkeduserList: [], // 选中的人员(复选框)
      isshowpassword: true,
      deptdata: [], // 部门列表
      roleUserData: [], // 角色列表
      edituserInfo: {}
    }
  },
  mounted() {
    this.getdeptList()
    this.getRoledata()
  },
  methods: {
    // 编辑人员
    edituserInfo_save() {
      const _this = this
      let isgoing = true
      this.$refs['userruleForm'].validate((valid) => {
        if (!valid) {
          isgoing = false
          console.log('error submit!!')
          return false
        }
      })
      if (isgoing) {
        _this.add_fullscreenLoading = true
        // 处理部门数据
        _this.userInfo.PermissionGroupInfoList = []
        this.usercheckeddeptarr.forEach((ele) => {
          if (ele.deptvalue) {
            _this.userInfo.PermissionGroupInfoList.push({ IDX: ele.deptvalue[ele.deptvalue.length - 1], UserIdentity: ele.userdepttype })
          }
        })
        $request.SubmitUser(this.userInfo).then(res => {
          _this.add_fullscreenLoading = false
          if (res.RetCode === '0') {
            _this.$parent.getSelectUserQueryListOfDept()
            _this.adduserVisible = false
            _this.$message('操作成功')
          } else {
            _this.$message('操作失败：' + res.RetMsg)
          }
        })
      }
    },
    // 确认选中人员
    doconnuser() {
      const _this = this
      // selectuserType 1： 角色添加人员 2 部门添加人员 3：新增角色时 4：新增部门时部门主管 5：新增部门时分管领导 6: 编辑人员 直属领导
      switch (_this.selectuserType) {
        case 1:
          _this.doroleconnuser()
          break
        case 2:
          _this.dodeptconnuser()
          break
        case 3:
          _this.doeditroleconnuser()
          break
        case 4:
          _this.doeditdeptconnuser()
          break
        case 5:
          _this.doeditdeptconnuser2()
          break
        case 6:
          _this.doedituserconnuser()
          break
        default: console.log('未找到需要关联人员的组织！！_type：' + _this.selectuserType)
      }
    },
    // 编辑人员 直属领导
    doedituserconnuser() {
      const _this = this
      if (_this.checkeduserIdList.length > 1) {
        _this.$message('直属领导只能选择一个人！，当前选中人数：' + _this.checkeduserIdList.length)
        return false
      }
      _this.userInfo.LeaderUserID = _this.checkeduserIdList[0].IDX
      _this.userInfo.LeaderUserName = _this.checkeduserIdList[0].label
      _this.seletUserVisible = false
    },
    // 过滤树形数据
    alluserfilterNode(value, data) {
      if (!value) return true
      return data.label.indexOf(value) !== -1
    },
    // 选中人员
    checkeduser(ischecked, val, item) {
      const _this = this
      _this.pushuserarr(ischecked, item)
      if (item.children) {
        _this.dgcheck(ischecked, item, 1)
      }
      console.log(this.checkeduserIdList)
    },
    // 处理角色选择人
    editrolecheckeduser(ischecked, data) {
      if (ischecked) {
        if (this.checkeduserIdList.indexOf(data) < 0) {
          this.checkeduserIdList.push(data)
        }
      } else {
        this.checkeduserIdList.splice(this.checkeduserIdList.indexOf(data), 1)
      }
    },
    //
    pushuserarr(ischecked, data) {
      const _this = this
      switch (_this.selectuserType) {
        case 1:
          _this.selectcheckeduser(ischecked, data)
          break
        case 2:
          _this.selectcheckeduser(ischecked, data)
          break
        case 3:
          _this.editrolecheckeduser(ischecked, data)
          break
        case 4:
          _this.editrolecheckeduser(ischecked, data)
          break
        case 5:
          _this.editrolecheckeduser(ischecked, data)
          break
        case 6:
          _this.editrolecheckeduser(ischecked, data)
          break
        default: console.log('未找到需要关联人员的组织！！_type：' + _this.selectuserType)
      }
    },
    // pushArrytype  -1 不需要push  1 push到 checkeduserIdList  数组里
    dgcheck(ischecked, data, pushArrytype) {
      const _this = this
      data.ischecked = ischecked
      if (pushArrytype === 1) {
        _this.pushuserarr()
      }
      if (data.children) {
        data.children.forEach(element => {
          return _this.dgcheck(ischecked, element, pushArrytype)
        })
      }
    },
    // 新增人员部门 初始化数据
    userdeptpush() {
      // sourceData:{
      //   value: null,
      //   label: '',
      //   children: null
      // }
      const arr = {
        deptvalue: null,
        sourceData: this.deptdata,
        userdepttype: 0,
        userdepthandleChange: 'userdepthandleChange'
      }
      this.usercheckeddeptarr.push(arr)
    },
    // 删除新增人员部门数组
    removesuerdeptarr(_index) {
      this.usercheckeddeptarr.splice(_index, 1)
    },
    // 新增人员选中部门
    userdepthandleChange(value, index) {
      console.log(value)
    },
    // 移除角色人员
    removeUser(_index) {
      console.log(_index)
      this.addedUserData.splice(_index, 1)
    },
    // 获取部门数据接口  _type 1:左侧部门列表 2 新增人员部门列表
    getdeptList() {
      const _this = this
      $request.GetDeptList().then(res => {
        if (res.RetCode === '0') {
          _this.deptdata = res.Message
        }
      })
    },
    // 获取 1 列表 2 新增人员列表
    getRoledata() {
      const _this = this
      $request.GetRoleList({ pageIndex: 1, pageSize: -1 }).then(res => {
        if (res.RetCode === '0') {
          _this.roleUserData = res.Message
          _this.userrolesDataList = res.Message
        }
      })
    },
    // 打开新增人员 adduserVisible = true
    openaddUser() {
      const _this = this
      // 初始化选择部门的信息
      _this.userrolesDataList = _this.roleUserData
      _this.initaddUser()
      _this.isshowpassword = true
    },
    // 初始化新增人员框
    initaddUser() {
      const _this = this
      _this.editUserInfo.boxtitile = '新增人员'
      _this.editUserInfo.IsEdit = true
      // 初始化部门信息
      _this.usercheckeddeptarr = [{
        deptvalue: null,
        sourceData: _this.deptdata,
        userdepttype: 0,
        userdepthandleChange: 'userdepthandleChange'
      },
      {
        deptvalue: null,
        sourceData: _this.deptdata,
        userdepttype: 0,
        userdepthandleChange: 'userdepthandleChange'
      }]
      // 初始化人员
      _this.userInfo = {
        UserID: 0,
        NickName: '', // 人员名称
        UserName: '', // 账号
        ExeID: '', // 用户编号
        Pwd: '123456',
        repwd: '123456',
        EMail: '',
        Mobile: '',
        Sex: null,
        JobName: '',
        usernum: '',
        OfficeTel: '', // 办公电话
        QQ: '', // qq号
        WorkNum: '', // 工号
        PermissionGroupInfoList: [], // 部门
        IsValid: 1, // 是否启用(1：启用 0：不启用)
        RoleList: [],
        LeaderUserID: 0, // 直属领导,
        LeaderUserName: '', // 直属领导
        UserClass: 2
      }
      _this.adduserVisible = true
    },
    // 获取选择人员时 ，成员列表 _type 选择人员类型 1： 角色添加人员 2 部门添加人员 3：新增角色时 4：新增部门时 部门主管 5: 新增部门时 分管领导 6：新增人员时，直属领导
    openconnUser(_type) {
      const _this = this
      _this.isshowpassword = true
      _this.selectuserType = _type
      _this.checkeduserIdList = [] // 清空选择的人员项
      _this.getSelectUserQueryListOfDept()
      _this.seletUserVisible = true
    },
    // 获取选择人员时 ，成员列表
    getSelectUserQueryListOfDept() {
      const _this = this
      _this.add_fullscreenLoading = true
      _this.connuserlist = []
      $request.SelectUserQueryListOfDept().then(res => {
        _this.add_fullscreenLoading = false
        if (res.RetCode === '0') {
          _this.connuserlist = res.Message
        }
      })
    },
    // selectUseractiveNamehandleClick
    selectUseractiveNamehandleClick(val) {
      console.log(val)
    },
    // 新增人员时，设置角色
    userroleschange(val) {
      console.log(val)
    }
  } // filters ends
}
</script>
<style scoped lang='scss'>
@import '@/style/search.scss';
@import '@/style/contactsIndex.scss';
</style>
<style lang='scss'>
.userstatus {
  .el-icon-remove {
    color: #9696a5;
  }
}
.el-table .userrowColor {
  color: #c6c6d4;
}
.adduserdia .el-dialog  .el-dialog__body {
  height: 400px;
  overflow: auto;
}
.roleremakr .el-input__inner{
  height:100px;
}
.roleuser .el-icon-plus:before {
    color: #f35e07;
}
</style>
